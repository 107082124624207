<template>
    <main>
      <b-button variant="transparent" class="b-light" @click="$router.back()"
        ><i class="b7-back"></i> Regresar </b-button>
      <section class="d-flex justify-content-center">
        <div class="purchase-box">
          <div class="purchase-header">
            <h2 class="h2-bold">Compra</h2>
            <img src="../../assets/images/logo.webp" alt="" />
          </div>
          <div class="purchase-content d-flex flex-column">
            <div>
              <h4 class="h4-semibold text-left">Seleccionar tiempo</h4>
            </div>

            <section class="time">
              <div class="time__grid">
                <label
                  class="time__card click"
                  :class="select == month.id ? 'bg-image' : ''"
                  v-for="month in plans"
                  :key="month.months"
                  @click="selectMontht(month.id)"
                >
                  <div class="time__logo">
                    <img src="../../assets/images/brand.png" alt="" />
                    <p class="time__value">{{ month.months }}M</p>
                  </div>
                  <div class="time__card-radio">
                    <div class="time__card-box w-100">
                      <h4 class="h4-semibold">{{ month.name }}</h4>
                      <div class="time__card-grid mt-4">
                        <h5 class="h5-regular">Meses</h5>
                        <h4 class="h4-bold">{{ month.months }} Mes<span v-if="month.months > 1">es</span> </h4>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </section>
            <div class="purchase-buttons d-flex justify-content-end mt-3">
              <b-button variant="outline-primary">Cancelar</b-button>
              <b-button
                variant="primary"
                :disabled="select == null"
                @click="$router.push({
                    name: 'Purchase-Method',
                    params: {
                        type: type,
                        id: id,
                        extra: select
                    },
                    query: {
                      custom: custom
                    }
                })"
                >
                Confirmar compra
                </b-button>
            </div>
          </div>
        </div>
      </section>
    </main>
  </template>
  <script>
  import { mapActions, mapState } from "vuex";
  
  export default {
    props: ["id", "type"],
    data() {
      return {
        select: null,
        custom: null
      };
    },
    created () {
        this.getData()
        if(this.$route.query.custom) {
          this.custom = this.$route.query.custom
        }
    },
    methods: {
      ...mapActions("plan", ["getPlans"]),
      getData () {
        this.getPlans()
      },
      selectMontht(id) {
        this.select = id;
        /* let member = this.memberships.find(el => el.id == this.id)
        if(member) {
            if (member.price >= min) {
            }
        } */
      },
    },
    computed: {
        ...mapState("membership", ["memberships"]),
        ...mapState("plan", ['plans'])
    }
  };
  </script>
  <style scoped>
  main {
    display: grid;
    gap: 32px;
  }
  
  .b-light {
    color: #fff;
    margin-right: auto;
  }
  .purchase-box {
    width: 45%;
    min-width: 890px;
    background: #2a2b3d;
    border-radius: 24px;
    overflow: hidden;
    color: #fff;
  }
  @media (max-width: 1100px) {
    .purchase-box {
      min-width: unset;
      width: 100%;
    }
  }
  .purchase-box .purchase-header {
    background: url("../../assets/images/bg-header-modal.webp");
    padding: 3.25rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: cover;
    background-position: center bottom;
  }
  .purchase-box .purchase-header img {
    height: 4rem;
  }
  .purchase-box .purchase-content {
    padding: 24px;
    gap: 24px;
  }
  .purchase-box .purchase-content .item {
    gap: 16px;
    padding: 16px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.05);
  }
  .purchase-box .purchase-content .item img {
    width: 40px;
    height: 40px;
  }
  .purchase-box .purchase-content .item .l-light {
    color: #b5c2d7;
  }
  .purchase-box .purchase-content .item i {
    border-radius: 6.25rem;
    background: rgba(255, 255, 255, 0.1);
    padding: 8px;
    font-size: 16px;
    transform: rotate(-90deg);
  }
  .purchase-box .purchase-content .purchase-buttons {
    gap: 16px;
  }
  .purchase-box .purchase-content i.b7-done {
    color: #48a254;
    font-size: 78px;
  }
  
  .time {
    display: grid;
    gap: 3.2rem;
  }
  .time__logo {
    width: 10rem;
    margin: 0 auto;
    position: relative;
  }
  .time__btns {
    display: flex;
    justify-content: flex-end;
  }
  .time__value {
    position: absolute;
    font-size: 15px;
    font-style: italic;
    font-weight: 900;
    line-height: 21px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  .time__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;
  }
  @media (max-width: 650px) {
    .time__grid {
      grid-template-columns: 1fr;
    }
  }
  .time__card {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    flex: 1 0 0;
    border-radius: 24px;
    border: 1px solid var(--brand-border, rgba(255, 255, 255, 0.2));
  }
  .time__card-radio {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    width: 100%;
  }
  .time__card-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  </style>
  